import React, { useState, useEffect, useReducer, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Chip } from "@material-ui/core";

import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding: "0 20px",
        display: 'flex',
        flexDirection: "column",
        rowGap: "1.5rem",
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    selectGroup: {
        display: "flex",
        columnGap: "1rem",
    },
    selectWrapper: {
        border: "1px solid black",
        borderRadius: '2px',
        padding: '5px',
        "& select": {
            border: "none",
            "&:focus, &:focus-visible": {
                outline: "none",
            }
        },
        "& label": {
            color: "grey"
        }
    }


}));

const Reports = () => {
    const classes = useStyles();

    const [tickets, setTickets] = useState([]);


    const [filteredTickets, setFilteredTickets] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedQueue, setSelectedQueue] = useState("");
    const [selectedAttendant, setSelectedAttendant] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/report-tickets/");
                console.log(response); // isso está sendo impresso corretamente

                // Utilize response.data diretamente
                setTickets(response.data.tickets);
                console.log(response.data);
            } catch (error) {
                console.error("Erro ao processar o pedido:", error);
            }
        };

        fetchData();
    }, []);

    const getStatusLabel = (status) => {
        switch (status) {
            case 'pending':
                return 'Aguardando';
            case 'open':
                return 'Atendendo';
            case 'closed':
                return 'Fechado';
            default:
                return status;
        }
    };

    useEffect(() => {
        let filtered = tickets;

        if (selectedQueue !== "") {
            filtered = filtered.filter(ticket => ticket.queueId === selectedQueue);
        }

        if (selectedAttendant !== "") {
            filtered = filtered.filter(ticket => ticket.userId === selectedAttendant);
        }

        if (selectedStatus !== "") {
            filtered = filtered.filter(ticket => ticket.status === selectedStatus);
        }

        setFilteredTickets(filtered);
    }, [selectedQueue, selectedAttendant, selectedStatus, tickets]);

    const handleQueueChange = (event) => {
        setSelectedQueue(event.target.value);
    };

    const handleAttendantChange = (event) => {
        setSelectedAttendant(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };



    return (
        <div className={classes.mainContainer}>
            <h1>Reports</h1>
            <p>This is the Reports page.</p>
            <div className={classes.selectGroup}>
                <div className={classes.selectWrapper}>
                    <label className={classes.selectLabel}>Fila:</label>
                    <select value={selectedQueue} onChange={handleQueueChange}>
                        <option value="">Todos</option>
                        <option value="10">Ten</option>
                        <option value="20">Twenty</option>
                        <option value="30">Thirty</option>
                    </select>
                </div>
                <div className={classes.selectWrapper}>
                    <label className={classes.selectLabel}>Atendente:</label>
                    <select value={selectedAttendant} onChange={handleAttendantChange}>
                        <option value="">Todos</option>
                        <option value="10">Ten</option>
                        <option value="20">Twenty</option>
                        <option value="30">Thirty</option>
                    </select>
                </div>
                <div className={classes.selectWrapper}>
                    <label className={classes.selectLabel}>Status:</label>
                    <select value={selectedStatus} onChange={handleStatusChange}>
                        <option value="">Todos</option>
                        <option value="pending">Aguardando</option>
                        <option value="open">Atendendo</option>
                        <option value="closed">Fechado</option>
                    </select>
                </div>
            </div>
            <div>
                <Paper className={classes.mainPaper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Ticket</TableCell>
                                <TableCell align="center">Atendente</TableCell>
                                <TableCell align="center">Fila</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTickets.map((ticket) => (
                                <TableRow key={ticket.id} style={{ cursor: "pointer" }}>
                                    <TableCell align="center">{ticket.id}</TableCell>
                                    <TableCell align="center">{ticket.userId}</TableCell>
                                    <TableCell align="center">{ticket.queueId}</TableCell>
                                    <TableCell align="center">{getStatusLabel(ticket.status)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        </div>
    );
};

export default Reports;
